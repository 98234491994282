.container {
	display: grid;
	grid-template-columns: auto auto auto auto auto auto auto auto auto;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
}

.logoWrapper {
	width: 100px;
	height: 100px;
	border-radius: 15px;
	margin: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 10px;
	color: cornsilk;
	transition: 0.5s;
	clip-path: polygon(
		50% 0%,
		90% 20%,
		100% 87%,
		75% 100%,
		25% 100%,
		0 88%,
		10% 20%
	);
}
.logoWrapper:hover {
	animation: shake 5.82s cubic-bezier(0.86, 0.87, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}
@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
.techStacks img {
	width: 80px;
	margin: 10px;
}
.heading {
	width: fit-content;
	margin: auto;
	color: #8993b1;
	line-height: 40px;
}
.borderBottom {
	border-bottom: 3px solid #69c5f0;
	width: 100px;
	margin: 5px auto;
	border-radius: 10px;
}
.logoWrapper i {
	margin-top: 10px;
	margin-bottom: 5px;
	font-size: 40px;
}
.logoWrapper p {
	font-size: 1.1em;
	margin: 5px !important;
}

@media only screen and (max-width: 1150px) {
	.container {
		grid-template-columns: auto auto auto auto;
		grid-template-rows: auto auto;
	}
}
/* @media only screen and (max-width: 650px) {
	.logoWrapper {
		padding: 20px 20px;
	}
} */
@media only screen and (max-width: 600px) {
	.container {
		grid-template-columns: auto auto auto;
		grid-template-rows: auto auto auto;
	}
	/* .logoWrapper {
		padding: 20px 20px;
	} */
}
@media only screen and (max-width: 450px) {
	.container {
		grid-template-columns: auto auto;
		grid-template-rows: auto auto auto auto;
	}
}

.footer {
	width: 100%;
	padding: 8vh 0 5vh;
	margin-top: 5vh;
}
.container {
	width: fit-content;
	margin: auto;
}
.paragraph {
	width: 45vw;
	font-size: 1.2em;
	margin: auto;
}
.contactOptions {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3rem 0;
}
.contactOptions i {
	font-size: 40px;
	margin: 20px 40px;
	transition: 0.5s;
}

.contactOptions i:hover {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}
@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
.line {
	width: 80%;
	height: 1px;
	margin: 0 auto 5vh;
}
.copyright {
	width: fit-content;
	margin: auto;
	font-size: 1rem;
	opacity: 0.7;
}
@media screen and (max-width: 800px) {
	.paragraph {
		width: 60vw;
		font-size: 1.2em;
	}
	.contactOptions i {
		/* font-size: 30px; */
		margin: 20px 5vw;
	}
	.contactOptions {
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 500px) {
	.paragraph {
		width: 70vw;
		font-size: 1em;
		margin: auto;
	}

	.contactOptions i {
		font-size: 30px;
		/* color: blanchedalmond; */
		margin: 20px 5vw;
	}
	.copyright {
		width: 70vw;
		text-align: center;
	}
}

html {
	scroll-behavior: smooth;
}
body {
	padding: 0;
	margin: 0;
	max-width: 100vw;
	font-family: 'Roboto Mono', monospace;
}
body::before {
	content: '';
	position: fixed;
	width: 100%;
	height: 100%;
	opacity: 0.1;
	background: linear-gradient(#7f04f1, #3bf8b9);
	clip-path: circle(18% at right 60%);
}
body::after {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.2;
	background: linear-gradient(#1b2aff, #11dbff);
	clip-path: circle(10% at 5% 5%);
}
.intro {
	max-width: 50vw;
	text-align: left;
	color: #8993b1;
	padding-left: 10vw;
	transition: 0.5s;
	font-size: clamp(10px, 2vw, 15px);
	position: fixed;
}

.experience {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 80px 0;
}
.extraIntro {
	width: 60%;
	padding: 30px;
	margin: auto;

	font-weight: 400;
	font-style: italic;
	font-size: 2em;
	margin-top: -10px;
	color: #7999ad;
}
.name {
	color: #ccd6f6;
	font-size: clamp(40px, 8vw, 80px);
}
.profile {
	margin-top: 0;
	height: 100vh;
	/* width: 100%; */
	display: flex;
	align-items: center;
	background-size: 550px;
	background-color: #0e0914;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position-x: 60vw;
	background-position-y: 10vh;
}
.profileImage {
	width: 400px;
	height: 400px;
	border-radius: 50%;
	overflow: hidden;
	margin-left: 60px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	border-bottom: 7px solid #9802be;
	transform: rotate(-2deg);
	transition: 0.5s;
	position: fixed;
	right: 8vw;
}
.profileImage img {
	width: 400px;
}

.techStacks {
	padding: 50px 0;
	margin: 5vh 0;
}
.projects {
	width: fit-content;
	margin: auto;
	padding: 8vh 0;
}
.heading {
	width: fit-content;
	margin: auto;
	color: #8993b1;
	line-height: 40px;
}
.borderBottom {
	border-bottom: 3px solid #0282bd;
	width: 200px;
	margin: 10px auto;
	border-radius: 10px;
}
.line {
	width: 80%;
	height: 1px;
	margin: 150px auto;
}
.resumeBtn {
	display: flex;
	justify-content: center;
	align-items: center;
}
.resumeBtn i {
	margin-left: 10px;
	font-size: 20px;
}

@media screen and (max-width: 1300px) {
	.profile {
		padding-right: 5vw;
	}
	.intro {
		width: 50%;
		padding-left: 5vw;
	}
	.profileImage {
		margin-left: 30px;
	}
}
@media screen and (max-width: 900px) {
	.profileImage {
		width: 300px;
		height: 300px;
	}
	.profileImage img {
		width: 300px;
	}
}
@media screen and (max-width: 830px) {
	.profile {
		background-position-x: 50vw;
		background-position-y: 15vh;
		background-size: 50vw;
		background-attachment: fixed;
	}
	.intro {
		max-width: 45%;
	}
	.extraIntro {
		width: 70vw;
		font-size: 1.5rem;
	}
}
@media screen and (max-width: 830px) {
	.profile {
		background-position: bottom right;
	}
}
@media screen and (max-width: 650px) {
	.profile {
		margin: auto;
		min-height: 100vh;
		background-size: 100vw;
		background-position: center;
		background-attachment: fixed;
		background-blend-mode: soft-light;
		padding: 0;
	}
	.intro {
		min-width: 95% !important;
		text-align: center;
		padding: 3vh 0 !important;
		font-size: 0.9em;
		left: 2.5%;
		margin-top: 68vw;
		padding: 0;
	}

	.profileImage {
		position: fixed;
		width: 50vw;
		height: 50vw;
		top: 80px;
		right: 50%;
		transform: translateX(50%) !important;
	}
	.profileImage img {
		width: 50vw;
	}
	.name {
		font-size: 42px;
	}
}

@media screen and (max-width: 360px) {
	.profileImage {
		width: 40vw;
		height: 40vw;
		top: 70px;
		right: 50%;
		transform: translateX(50%) !important;
	}
	.profileImage img {
		width: 40vw;
	}
	.intro {
		margin-top: 30vh;
		font-size: 0.7em;
	}
	.name {
		font-size: 1em;
	}
}
